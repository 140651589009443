import { create } from "zustand";
import { useGlobalStore } from "../index";

interface AuthState {
  email: string;
  nickname: string;
  avatar: string;
  slug: string;
  sessionExpired: boolean;
  setSessionExpired: (expired: boolean) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  email: "",
  nickname: "",
  avatar: "",
  slug: "",
  sessionExpired: false,
  setSessionExpired: (expired) => set({ sessionExpired: expired }),
}));
